// Types
import { NullableString } from '../../../types/common';
// Modules
import { getCookie, removeCookie } from './cookie';
// Constants
import { CW_REFERRER_COOKIE_NAME, CW_REFERRER_CURRENT_COOKIE_NAME } from '../../../constants/index';

/**
 * Gets CW referrer cookie value from local domain
 */
export const getCWReferrerCookie = (): NullableString =>
  getCookie(CW_REFERRER_COOKIE_NAME) ?? getCookie(CW_REFERRER_CURRENT_COOKIE_NAME);

/**
 * Deletes CW referrer cookie
 */
export const removeCWReferrerCookie = (): void => {
  removeCookie({ name: CW_REFERRER_COOKIE_NAME });
  removeCookie({ name: CW_REFERRER_COOKIE_NAME, domain: window.location.hostname });
  removeCookie({ name: CW_REFERRER_CURRENT_COOKIE_NAME });
  removeCookie({ name: CW_REFERRER_CURRENT_COOKIE_NAME, domain: window.location.hostname });
};
