// Types
import { Dimensions, Geometry } from '../../types/document';

/**
 * Check collection of html elements for visibility (aggregate its data-elm atributes)
 * @param dataElms Collection of html elements for visibility check
 * @param dimensions Dimensions object (scroll position, size, ...)
 */
export const _getVisibleDataElm = (dataElms: HTMLElement[], dimensions: Dimensions): string[] => {
  const { x, y, w, h } = dimensions;
  const results = [];

  for (let i = 0; i < dataElms.length; i++) {
    let elm = dataElms[i];
    let left = elm.offsetWidth / 2;
    let top = elm.offsetHeight / 2;
    while (elm) {
      left += elm.offsetLeft;
      top += elm.offsetTop;
      elm = elm.offsetParent as HTMLElement;
    }

    if (left >= x && left <= x + w && top >= y && top <= y + h) {
      results.push(dataElms[i].getAttribute('data-elm'));
      dataElms.splice(i, 1);
      i--;
    }
  }

  return results;
};

/**
 * Get scroll position / dimensions of screen / root element
 */
export const _getDimensions = (): Dimensions => {
  const x = document.documentElement.scrollLeft || document.body.scrollLeft || 0;
  const y = document.documentElement.scrollTop || document.body.scrollTop || 0;
  const w = document.documentElement.clientWidth || document.body.clientWidth || 0;
  const h = document.documentElement.clientHeight || document.body.clientHeight || 0;
  const page = document.body.offsetWidth + ',' + document.body.offsetHeight;
  const scrn = screen.width + ',' + screen.height + ',' + screen.colorDepth;

  return { x, y, w, h, page, scrn };
};

/**
 * Generate geometry object which can be attached to hit data (d: {...})
 * @param dataElms Collection (array) of html elements for visibility check
 */
export const getGeometry = (dataElms: HTMLElement[] = []): Geometry => {
  if (!document.body) {
    return {};
  }

  let geometry: Geometry = {};

  const dimensions = _getDimensions();

  const { x, y, w, h, page, scrn } = dimensions;

  geometry = {
    scroll: `${x},${y}`,
    port: `${w},${h}`,
    page,
    screen: scrn,
  };

  const visible = _getVisibleDataElm(dataElms, dimensions);
  if (visible.length) {
    geometry.elm = visible.join(',');
  }
  return geometry;
};

// API
export default { getGeometry };
