// Types
import { DOT } from '../../classes/dot.class';
import { KeyValuePairs } from '../../types/common';
import { Hit, IdsData, SavedHit } from '../../types/hit';
// Methods
import { getIdentityObject } from '../hit/composeData';
// Constants
import { LAZY_HITTING_ENABLED, LAZY_HITTING_DISABLED } from '../../constants/index';

/* Set of allowed attributes */
const commonCfgList: KeyValuePairs<string> = {
  premium: 'premium',
  ids: 'ids',
};

/**
 * Adds values from additionalCfg to hit. Value is added only when is declared in commonCfgList
 *
 * @param hit saved Hit object
 * @param additionalCfg additional information
 */
export const _addAdditionalCfg = (hit: Hit, additionalCfg: KeyValuePairs<unknown>): void => {
  for (const p in additionalCfg) {
    if (commonCfgList[p] !== undefined) {
      hit[commonCfgList[p]] = additionalCfg[p];
    }
  }

  // update all identities automatically
  hit.ids = getIdentityObject() || ({} as IdsData);
};

/**
 * Clears lazyHitting queue
 *
 * @param queue array that will be cleared
 */
export const _clearHitQueue = (queue: SavedHit[]): void => {
  queue.length = 0;
};

/**
 * Check if lazyHitting is enabled
 *
 * @param status current lazyHitting status
 */
export const shouldLazyHit = (status: number): boolean => {
  return status === LAZY_HITTING_ENABLED;
};

/**
 * Adds hit to lazyHitting queue
 *
 * @param queue array where hit will be saved
 * @param hitToSave hit which will be saved and send later
 */
export const addToHitQueue = (queue: SavedHit[], hitToSave: SavedHit): void => {
  queue.push(hitToSave);
};

/**
 * Sends saved hits one by one with additional information
 *
 * @param dot DOT instance
 * @param additionalCfg additional information
 */
export const sendSavedHits = (dot: DOT, additionalCfg?: KeyValuePairs<unknown>): void => {
  dot.hitQueue.forEach((item) => {
    _addAdditionalCfg(item.hit, additionalCfg || {});
    dot.hit(item.hit.action, item.hit, item.callback, item.useFetch);
  });
  _clearHitQueue(dot.hitQueue);
};

/**
 * Initialize lazy hitting for DOT instance
 * @param dot DOT instance
 */
export const initLazyHitting = (dot: DOT): void => {
  dot.lazyHittingStatus = LAZY_HITTING_ENABLED;

  // tries to send saved hits before user leaves page
  const callback = (): void => {
    sendSavedHits(dot);
  };

  dot.sentBeforeUnload = callback;

  window.addEventListener('beforeunload', dot.sentBeforeUnload, false);
};

/**
 * Turn off lazy hitting for DOT instance and sends all saved hits with additional info
 * @param dot DOT instance
 * @param additionalConfig additional config which will be added to all saved hits
 */
export const finishLazyHitting = (dot: DOT, additionalConfig: KeyValuePairs<unknown>): void => {
  dot.lazyHittingStatus = LAZY_HITTING_DISABLED;

  window.removeEventListener('beforeunload', dot.sentBeforeUnload, false);
  dot.sentBeforeUnload = null;

  sendSavedHits(dot, additionalConfig);
};

// API
export default {
  shouldLazyHit,
  addToHitQueue,
  sendSavedHits,
  initLazyHitting,
  finishLazyHitting,
};
