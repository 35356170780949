// Types
import { KeyValuePairs } from '../../types/common';
import { DOT } from '../../classes/dot.class';
import { Config, Service, ConfigValue } from '../../types/config';
// Methods
import { getCookie } from '../utils/cookie/cookie';
import { getAPIHost, replaceImedia } from '../utils/general/url';
import { updateIdentitiesFromConfig } from '../identityObject/identityObject';
import { validateConfig } from './validation';
// Constants
import { EVENTS, SERVICE_VARIANT, PROTOCOL } from '../../constants/index';

/**
 * Return config object for specified DOT variant
 * @param variant DOT variant
 */
export const getConfig = (variant?: Service): Config => {
  const config: Config = {
    host: getAPIHost(variant),
    url: PROTOCOL + '//__HOST__/hit',
    thirdPartyCookieUrl: PROTOCOL + '//__HOST__/sid',
    delay: 800,
    query: '',
    service: '',
    lsid: '',
    id: '',
    rusId: '',
    said: '',
    loginState: null,
    version: '1.0',
    spa: false,
    pageViewId: '',
    impress: true,
    abtest: '',
    serviceVariant: '',
    hitTimeout: 60000,
    // eslint-disable-next-line camelcase
    u_agrm: null,
    premium: null,
    spenttimeSpaReset: true,
    forceBeacon: false,
    dopParamCleanup: true,
    propagateHits: false,
    badge: false,
  };

  switch (variant) {
    case SERVICE_VARIANT.BASE:
      return {
        ...config,
      };

    case SERVICE_VARIANT.TV:
      return {
        ...config,
        mousedown: true,
        mousedownDataDotData: true,
        url: 'http://__HOST__/hit',
      };

    case SERVICE_VARIANT.NANO:
      return {
        ...config,
        serverSideRefresher: getCookie('szncsr') !== null,
      };

    case SERVICE_VARIANT.SMALL:
      return {
        ...config,
        mousedown: true,
        load: true,
        error: false,
        mousedownDataDotData: true,
        lazyLoadingEnabled: false,
        serverSideRefresher: getCookie('szncsr') !== null,
      };

    case SERVICE_VARIANT.FULLTEXT:
      return {
        ...config,
        mousedown: true,
        load: true,
        error: false,
        mousedownDataDotData: true,
        lazyLoadingEnabled: false,
        serverSideRefresher: getCookie('szncsr') !== null,
      };

    case SERVICE_VARIANT.PARTNER:
      return {
        ...config,
        serverSideRefresher: getCookie('szncsr') !== null,
      };

    default:
      return config;
  }
};

/**
 * Sends hit after configuration, if allowed
 * @param dot DOT instance
 */
export const _fireAfterCfgEvent = (dot: DOT): void => {
  if (dot.fireEventAfterCfg) {
    const event = new CustomEvent(EVENTS.AFTER_CFG_EVENT);
    window.dispatchEvent(event);
  }
};

/**
 * Turns on hitting after the first call of configure()
 * Returns The name of the custom event.
 * @param dot DOT instance
 */
export const enableAfterCfgEvent = (dot: DOT): string => {
  dot.fireEventAfterCfg = true;
  return EVENTS.AFTER_CFG_EVENT;
};

/**
 * Configures the DOT instance
 * @param dot DOT instance
 * @param customConfig Custom configuration object
 * @param impressCallback called when impress is sent
 */
export const configure = (dot: DOT, customConfig?: KeyValuePairs<unknown>): void => {
  // validate config values
  validateConfig(customConfig);

  // set data
  for (const p in customConfig) {
    if (dot._cfg[p] !== undefined) {
      dot._cfg[p] = customConfig[p];
    }
  }

  updateIdentitiesFromConfig(customConfig);

  // repair host
  // TODO remove when all services change it to seznam domain
  dot._cfg.host = replaceImedia(dot._cfg.host);

  if (dot._cfg.url === '//h.imedia.cz/hit/?') {
    dot._cfg.url = '//__HOST__/hit';
  }

  dot.log('Configure:', 'table', dot._cfg);
};

/**
 * Whether the configure() was called
 *
 * @param dot DOT instance
 */
export const isInstanceConfigured = (dot: DOT): boolean => dot.configured;

/**
 * Returns value from instance config
 * @param dot DOT instance
 * @param key key of config value
 */
export const getValue = (dot: DOT, key: string): ConfigValue | null => {
  return dot._cfg[key] ?? null;
};

// API
export default { getConfig, configure, isInstanceConfigured, enableAfterCfgEvent, getValue };
