import { debugFactory } from '@iva/debugger';
import { Config, Debug } from '@iva/debugger/dist/esm/types';
import { LOG_COLOR_BG, SERVICE_NAME, LOG_COLOR_DEFAULT } from '../../constants/index';

const DEBUG_CONFIG = {
  name: SERVICE_NAME,
  bColor: LOG_COLOR_BG,
  fColor: LOG_COLOR_DEFAULT,
};

// Use the parameter ?sznivadbg=1 or ?sznivadbg=DOT for debugging

export const dotDebugFactory = (config: Partial<Config>): Debug => debugFactory({ ...DEBUG_CONFIG, ...config });

export default { dotDebugFactory };
