/* eslint-disable camelcase */
// Types
import { DOT } from '../../classes/dot.class';
import { NullableString } from 'types/common';
import { Hit, CommonData, IdsWithVersion } from '../../types/hit';
import { Config } from '../../types/config';
// Methods
import { getDocumentUrl } from '../utils/general/url';

// IVA Packages
import IS from '@iva/identity-storage';

/**
 * Gets identity object including login, sid, udid and euconsent
 */
export const getIdentityObject = (): IdsWithVersion | null => {
  const ids = IS.getIdentities({ cacheEnough: true, justSettled: false }, null, true);
  return IS.prepareIdsForBe(ids);
};

/**
 * Serialize hit with JSON.stringify
 *
 * @param hit hit data defined by user
 */
export const serializeHit = (hit: Hit): NullableString => {
  if (!hit || Object.keys(hit).length === 0) {
    return null;
  }

  try {
    return JSON.stringify(hit);
  } catch {
    return null;
  }
};

/**
 * Adds u_agrm into hit scroll, spenttime, dop-.+. True if flag added
 *
 * @param config DOT configuration
 * @param hit all hit data
 */
export const addUserAgreement = (config: Config, hit: Hit): boolean => {
  const regex = /^dop-.|^spenttime$|^scroll$/;

  if (config.u_agrm !== null && hit.data && typeof hit.data.action === 'string' && regex.test(hit.data.action)) {
    hit.data.u_agrm = config.u_agrm;
    return true;
  }

  return false;
};

/**
 * Gets common hit data from DOT configuration and website
 *
 * @param dot DOT instance
 */
export const getCommon = (dot: DOT): CommonData => {
  const data: CommonData = {
    service: dot._cfg.service,
    lsid: dot._cfg.lsid,
    id: dot._cfg.id,
    version: dot._cfg.version,
    pvid: dot._cfg.pageViewId,
    spa: dot._cfg.spa,
    url: getDocumentUrl(),
    lses: dot.lastSession,
    cdl: dot.cookieDeprecationLabel,
    ab: dot._cfg.abtest,
    serviceVariant: dot._cfg.serviceVariant,
    premium: dot._cfg.premium === null ? '' : dot._cfg.premium,
    ptitle: document.title,
    ids: getIdentityObject() || ({} as IdsWithVersion),
  };

  return data;
};

// API
export default {
  addUserAgreement,
  getCommon,
  serializeHit,
  getIdentityObject,
};
