import { parseUserAgent, isSafari } from '../utils/general/browser';

const isRedirectPage = (): boolean =>
  window.document.location.href.startsWith('https://www.seznam.cz/nastaveni-souhlasu');

export const shouldUseRedirect = (): boolean => {
  const { browser, majorVersion } = parseUserAgent(window.navigator.userAgent);

  if (isRedirectPage()) {
    return false;
  }

  return isSafari() || (browser === 'firefox' && majorVersion >= 101);
};
