import { Hit, SharedHitData } from '../../types/hit';

/**
 *
 * Update (write over / merge) shared hit data. Consider only valid shared data -> rest is filtered out
 */
export const handleSubmitedSharedData = (
  current: SharedHitData,
  submited: SharedHitData,
  merge = false
): SharedHitData => {
  // not and object submited
  if (!(submited && typeof submited === 'object' && !Array.isArray(submited))) {
    return current;
  }

  const output = {};
  // is object with relevant keys / values?
  try {
    for (const [key, data] of Object.entries(submited)) {
      if (data?.value !== undefined && Array.isArray(data?.actions || [])) {
        output[key] = data;
      }
    }
  } catch {
    // noop
  }

  return { ...(merge ? current : {}), ...output };
};

/**
 * Remove shared hit data (all / specific keys)
 */
export const cleanStoredSharedData = (current: SharedHitData, keys: unknown): SharedHitData => {
  const output = { ...current };
  if (Array.isArray(keys) && keys.length) {
    keys.forEach((key) => {
      delete output[key];
    });
    return output;
  } else {
    return {};
  }
};

/**
 * Filter out shared data according to hit "action" type
 */
export const extendHitWithSharedData = (hit: Hit, sharedData: SharedHitData) => {
  for (const [key, data] of Object.entries(sharedData)) {
    if (!data.actions || !data.actions.length || data.actions?.includes(hit.action)) {
      if (!hit.data) {
        hit.data = {};
      }
      hit.data[key] = data.value;
    }
  }
};
