import { KeyValuePairs } from '../../types/common';

export const _validateRusId = (rusId: any): number | '' => {
  const NumberRusId = parseInt(rusId, 10);
  return Number.isNaN(NumberRusId) ? '' : NumberRusId;
};

/**
 * Validates dot config object
 * @param customConfig DOT config object
 */
export const validateConfig = (customConfig: KeyValuePairs<unknown>): void => {
  if (!customConfig) {
    return;
  }

  if (customConfig.rusId !== undefined) {
    customConfig.rusId = _validateRusId(customConfig.rusId);
  }
};

// API
export default { validateConfig };
