// Types
import { KeyValuePairs, NullableString } from '../types/common';
import { Config, Service, ConfigValue } from '../types/config';
import { Delay, InfoObject, UserAgentData, SspAllowedSources } from '../types/dot';
import { Data, SavedHit, SharedHitData } from '../types/hit';
import { Spenttime } from '../types/spenttime';
import { Debug } from '@iva/debugger/dist/esm/types';
// Methods
import {
  getConfig,
  enableAfterCfgEvent,
  configure,
  isInstanceConfigured,
  getValue,
  _fireAfterCfgEvent,
} from '../modules/config/config';
import { init } from '../modules/init';
import { sendHit, sendHitAsync } from '../modules/hit/hit';
import { handleImpress, sendImpress } from '../modules/impress/impress';
import { delay } from '../modules/hit/delayedHit';
import { placeholder } from '../modules/logger/methodPlaceholder';
import { dotDebugFactory } from '../modules/logger/log';
import { getRandomColor } from '../modules/utils/general/string';
import { configureFromTopFrame } from '../modules/utils/html/iframe';
import { cleanStoredSharedData, handleSubmitedSharedData } from '../modules/hit/sharedData';
// Constants
import { LAZY_HITTING_DISABLED, LOG_COLOR_MIN, LOG_COLOR_MAX, SSP_ALLOWED_SOURCES } from '../constants/index';

export class DOT {
  /**
   * DOT variant
   */
  protected _variant: Service;

  /**
   * Timestamp of instance initialization
   */
  protected _ts: number;

  /**
   * Timestamp of instance initialization from localStorage
   */
  protected _lastSession: number;

  /**
   * Collection of html elements for visibility check
   */
  protected _dataElms: [];

  /**
   * Config object for sending delayed hits
   */
  protected _delayed: Delay;

  /**
   * Instance configuration status
   */
  protected _configured: boolean;

  /**
   * Referrer for SPA website
   */
  protected _SPAReferrer: string;

  /**
   * Last fcp value
   */
  protected _lastFCP: number;

  /**
   * Saved hits from lazy hitting
   */
  protected _hitQueue?: SavedHit[];

  /**
   * Lazy hitting status
   */
  protected _lazyHittingStatus?: number;

  /**
   * Saved hits waiting for SID sync
   */
  protected _postponedQueue?: SavedHit[];

  /**
   * SID syncing status
   */
  protected _synced?: boolean;

  /**
   * True when sid XHR is loaded
   */
  protected _cookieRequestDone?: boolean;

  /**
   * Reference to sid XHR
   */
  protected _cookieRequest?: XMLHttpRequest;

  /**
   * True when first impress hit has been sent
   */
  protected _firstImpressSent: boolean;

  /**
   * True when necessary impress right before adload has been sent
   */
  protected _impressBeforeAdloadSent: boolean;

  /**
   * Custom event after cfg() enable status
   */
  protected _fireEventAfterCfg: boolean;

  /**
   * Reference to function that tries to send all saved hits onbeforeunload
   */
  protected _sentBeforeUnload?: () => void;

  /**
   * Object to keep data about time spent on the site
   */
  protected _spenttimeState: Spenttime;

  /** spenttime configuration status
   */
  protected _spenttimeConfigured: boolean;

  /**
   * Instance configuration
   */
  protected _config: Config;

  /**
   * info object
   */
  protected _info: InfoObject;

  /**
   * Random instance ID to distinguish in the console logs
   */
  protected _instanceId: number;

  /**
   * Random color to distinguish in the console logs
   */
  protected _instanceColor: string;

  /**
   * UserAgentData from navigator object
   */
  protected _UAData: UserAgentData;

  /**
   * Chrome cookie deprecation label value
   */
  protected _cookieDeprecationLabel: string;

  /**
   * Internal configurated logger function
   */
  protected _log: Debug;

  /**
   * Data which are included in all hits
   */
  protected _sharedHitData: SharedHitData;

  /**
   * DOT constructor
   * @param variant DOT variant
   */
  constructor(variant?: Service) {
    this._variant = variant ? variant : null;
    this._ts = 0;
    this._lastSession = 0;
    this._dataElms = [];
    this._delayed = {
      action: '',
      timeout: null,
      count: 0,
    };
    this._configured = false;
    this._SPAReferrer = '';
    this._lastFCP = -1;
    this._hitQueue = [];
    this._lazyHittingStatus = LAZY_HITTING_DISABLED;
    this._postponedQueue = [];
    this._synced = false;
    this._cookieRequestDone = false;
    this._cookieRequest = null;
    this._firstImpressSent = false;
    this._impressBeforeAdloadSent = false;
    this._fireEventAfterCfg = false;
    this._sentBeforeUnload = null;
    this._config = getConfig(this._variant);
    this._info = {
      site: window.document.location.href,
      topConfig: null,
    };
    this._UAData = {
      model: '',
      platformVersion: '',
    };
    this._cookieDeprecationLabel = '';

    // Logger
    this._instanceId = Math.floor(Math.random() * 10000);
    this._instanceColor = getRandomColor(LOG_COLOR_MIN, LOG_COLOR_MAX);
    this._log = dotDebugFactory({ fColor: this._instanceColor });
    this._sharedHitData = {};
    this.log(`NEW DOT ${this._variant}`);

    init(this);
  }

  /**
   /=============================================================================
   /
   / Getters and Setters
   /
   /=============================================================================
  */

  /**
   * Gets _variant property
   */
  public get variant(): Service {
    return this._variant;
  }

  /**
   * Gets _ts property
   */
  public get ts(): number {
    return this._ts;
  }

  /**
   * Sets _ts property
   */
  public set ts(value: number) {
    this._ts = value;
  }

  /**
   * Gets _lastSession property
   */
  public get lastSession(): number {
    return this._lastSession;
  }

  /**
   * Sets _lastSession property
   */
  public set lastSession(value: number) {
    this._lastSession = value;
  }

  /**
   * Gets _dataElms property
   */
  public get dataElms(): [] {
    return this._dataElms;
  }

  /**
   * Sets _dataElms property
   */
  public set dataElms(value: []) {
    this._dataElms = value;
  }

  /**
   * Gets _delayed property
   */
  public get delayed(): Delay {
    return this._delayed;
  }

  /**
   * Sets _delayed property
   */
  public set delayed(value: Delay) {
    this._delayed = value;
  }

  /**
   * Gets _configured property
   */
  public get configured(): boolean {
    return this._configured;
  }

  /**
   * Sets _configured property
   */
  public set configured(value: boolean) {
    this._configured = value;
  }

  /**
   * Gets _SPAReferrer property
   */
  public get SPAReferrer(): string {
    return this._SPAReferrer;
  }

  /**
   * Sets _SPAReferrer property
   */
  public set SPAReferrer(value: string) {
    this._SPAReferrer = value;
  }

  /**
   * Gets _lastFCP property
   */
  public get lastFCP(): number {
    return this._lastFCP;
  }

  /**
   * Sets _lastFCP property
   */
  public set lastFCP(value: number) {
    this._lastFCP = value;
  }

  /**
   * Gets _hitQueue property
   */
  public get hitQueue(): SavedHit[] {
    return this._hitQueue;
  }

  /**
   * Sets _hitQueue property
   */
  public set hitQueue(value: SavedHit[]) {
    this._hitQueue = value;
  }

  /**
   * Gets _lazyHittingStatus property
   */
  public get lazyHittingStatus(): number {
    return this._lazyHittingStatus;
  }

  /**
   * Sets _lazyHittingStatus property
   */
  public set lazyHittingStatus(value: number) {
    this._lazyHittingStatus = value;
  }

  /**
   * Gets _postponedQueue property
   */
  public get postponedQueue(): SavedHit[] {
    return this._postponedQueue;
  }

  /**
   * Sets _postponedQueue property
   */
  public set postponedQueue(value: SavedHit[]) {
    this._postponedQueue = value;
  }

  /**
   * Gets _synced property
   */
  public get synced(): boolean {
    return this._synced;
  }

  /**
   * Sets _synced property
   */
  public set synced(value: boolean) {
    this._synced = value;
  }

  /**
   * Gets _cookieRequestDone property
   */
  public get cookieRequestDone(): boolean {
    return this._cookieRequestDone;
  }

  /**
   * Sets _cookieRequestDone property
   */
  public set cookieRequestDone(value: boolean) {
    this._cookieRequestDone = value;
  }

  /**
   * Gets _cookieRequest property
   */
  public get cookieRequest(): XMLHttpRequest {
    return this._cookieRequest;
  }

  /**
   * Sets _cookieRequest property
   */
  public set cookieRequest(value: XMLHttpRequest) {
    this._cookieRequest = value;
  }

  /**
   * Gets _firstImpressSent property
   */
  public get firstImpressSent(): boolean {
    return this._firstImpressSent;
  }

  /**
   * Sets _firstImpressSent property
   */
  public set firstImpressSent(value: boolean) {
    this._firstImpressSent = value;
  }

  /**
   * Gets _impressBeforeAdloadSent property
   */
  public get impressBeforeAdloadSent(): boolean {
    return this._impressBeforeAdloadSent;
  }

  /**
   * Sets _impressBeforeAdloadSent property
   */
  public set impressBeforeAdloadSent(value: boolean) {
    this._impressBeforeAdloadSent = value;
  }

  /**
   * Gets _fireEventAfterCfg property
   */
  public get fireEventAfterCfg(): boolean {
    return this._fireEventAfterCfg;
  }

  /**
   * Sets _fireEventAfterCfg property
   */
  public set fireEventAfterCfg(value: boolean) {
    this._fireEventAfterCfg = value;
  }

  /**
   * Gets _sentBeforeUnload property
   */
  public get sentBeforeUnload(): () => void {
    return this._sentBeforeUnload;
  }

  /**
   * Sets _sentBeforeUnload property
   */
  public set sentBeforeUnload(value: () => void) {
    this._sentBeforeUnload = value;
  }

  /**
   * Gets _spenttime property, object that keeps spenttime state
   */
  public get spenttimeState(): Spenttime {
    return this._spenttimeState;
  }

  /**
   * Sets _spenttimeState property, object that keeps spenttime state
   */
  public set spenttimeState(value: Spenttime) {
    this._spenttimeState = value;
  }

  /**
   * Gets _spenttimeConfigured property
   */
  public get spenttimeConfigured(): boolean {
    return this._spenttimeConfigured;
  }

  /**
   * Sets _spenttimeConfigured property
   */
  public set spenttimeConfigured(value: boolean) {
    this._spenttimeConfigured = value;
  }

  /**
   * Gets _config property
   */
  public get _cfg(): Config {
    return this._config;
  }

  /**
   * Gets _instanceId
   */
  public get instanceId(): number {
    return this._instanceId;
  }

  /**
   * Gets _UAData property
   */
  public get UAData(): UserAgentData {
    return this._UAData;
  }

  /**
   * Sets _UAData property
   */
  public set UAData(value: UserAgentData) {
    this._UAData = value;
  }

  /**
   * Gets _cookieDeprecationLabel property
   */
  public get cookieDeprecationLabel(): string {
    return this._cookieDeprecationLabel;
  }

  /**
   * Sets _cookieDeprecationLabel property
   */
  public set cookieDeprecationLabel(value: string) {
    this._cookieDeprecationLabel = value;
  }

  /**
   * Gets _sharedHitData property
   */
  public get sharedHitData(): SharedHitData {
    return this._sharedHitData;
  }

  /**
   /=============================================================================
   /
   / Public API
   /
   /=============================================================================
  */

  /**
   * Configures DOT instance
   *
   * @param customConfig config data object
   * @param impressCallback called when impress is sent
   */
  public cfg(customConfig: KeyValuePairs<unknown>, impressCallback?: () => void): void {
    configure(this, customConfig);

    if (this._cfg.impress) {
      handleImpress(this, impressCallback);
    }

    if (this.configured) {
      return;
    }
    this.configured = true;

    _fireAfterCfgEvent(this);
  }

  /**
   * Configure this DOT instance with the top window DOT configuration.
   * Call this instead of DOT.cfg() in an iframe.
   * @returns Promise of Config object used to config this instance
   */
  public cfgFromTopFrame(): Promise<Config | null> {
    return configureFromTopFrame(this);
  }

  /**
   * Returns true if DOT.cfg() has been called
   */
  public isConfigured(): boolean {
    return isInstanceConfigured(this);
  }

  /**
   * Turns on hitting after the first call of cfg()
   * Returns The name of the custom event.
   */
  public setAfterCfgEvent(): string {
    return enableAfterCfgEvent(this);
  }

  /**
   * Returns value from instance config
   * @param key key of config value
   */
  public getCfgValue(key: string): ConfigValue | null {
    return getValue(this, key);
  }

  /**
   * Returns instance config object
   */
  public getCfg() {
    return this._cfg;
  }

  /**
   * get additional info object filled from parent dot instance
   *
   * see modules/utils/iframe.ts
   */
  public getInfo(): InfoObject {
    return this._info;
  }

  /**
   * Sends impress hit
   *
   * @param callback called when impress is sent
   * @param impressData custom data which will be added to hit data (d:{...})
   */
  public impress(callback: () => void, impressData: KeyValuePairs<unknown>): void {
    sendImpress(this, callback, impressData);
  }

  /**
   * Returns new DOT instance
   */
  public getNewInstance(): DOT {
    return new DOT();
  }

  /**
   * Sends hit
   *
   * @param action event when hit is sent (event, load, etc.)
   * @param data advanced data
   * @param callback called when hit is sent. In case of any error is called with Error param
   * @param useFetch should use Fetch API for sending hit
   */
  // eslint-disable-next-line no-unused-vars
  public hit(action: string, data: Data = {}, callback?: (arg?: Error) => void, useFetch = false): void {
    sendHit(this, action, data, callback, useFetch);
  }

  /**
   * Sends hit asynchronously
   *
   * @param action event when hit is sent (event, load, etc.)
   * @param data advanced data
   * @param callback called when hit is sent. In case of any error, callback is called with Error param
   */
  // eslint-disable-next-line no-unused-vars
  public hitAsync(action: string, data: Data = {}, callback?: (arg?: unknown) => void): void {
    sendHitAsync(this, action, data, callback);
  }

  /**
   * Sends delayed hit
   * @param action delayed hit action
   */
  public _delay(action: string): void {
    delay(this, action);
  }

  /**
   * Gets allowed sources for SSP
   */
  public _getSspAllowedSources(): SspAllowedSources {
    return SSP_ALLOWED_SOURCES;
  }

  public load(): void {
    placeholder(this, 'load', 'load');
  }

  public error(...params: [e?: ErrorEvent, callback?: () => void]): void {
    placeholder(this, 'error', 'error', params);
  }

  public spenttime(): void {
    placeholder(this, 'spenttime', 'spenttime');
  }

  public cancelSpenttime(): void {
    placeholder(this, 'spenttime', 'cancelSpenttime');
  }

  public handleSid(): void {
    placeholder(this, 'linkdecoration', 'handleSid [deprecated]');
  }

  public runLinkdecoration(): void {
    placeholder(this, 'linkdecoration', 'runLinkdecoration');
  }

  public getSid(): NullableString {
    placeholder(this, 'linkdecoration', 'getSid');
    return null;
  }

  public _handleSid(): void {
    placeholder(this, 'Sbrowser sync SID', '_handleSid');
  }

  /**
   * Print message with DOT instance ID into console
   */
  public log(message: string, type: 'log' | 'warn' | 'error' | 'info' | 'table' = 'log', ...data: unknown[]): boolean {
    return this._log({
      subName: this._instanceId,
      type,
      message,
      ...data,
    });
  }

  public shouldLog(): boolean {
    return this._log();
  }

  /**
   * Shared hit data
   */
  public getSharedHitData(): SharedHitData {
    return this._sharedHitData;
  }
  public clearSharedHitData(keys?: string[]): void {
    this._sharedHitData = cleanStoredSharedData(this._sharedHitData, keys);
  }
  public setSharedHitData(data: SharedHitData, merge = false): void {
    this._sharedHitData = handleSubmitedSharedData(this._sharedHitData, data, merge);
  }
}
