/**
 * Generate random hexadecimal number
 * @param min Minimum (included)
 * @param max Maximum (excluded)
 * @param pad Pad with leading nulls to this length
 * @returns hexadecimal number
 */
export const getRandomHexdec = (min: number, max: number, pad = 0): string =>
  Math.floor(Math.random() * (max - min) + min)
    .toString(16)
    .padStart(pad, '0');

/**
 * Generate random color hexadecimal code
 * @param min Minimum amount for each color component (included)
 * @param max Maximum amount for each color component (excluded)
 * @returns Full hexadecimal color code including sharp sign
 */
export const getRandomColor = (min = 0x00, max = 0x100): string =>
  `#${getRandomHexdec(min, max, 2)}${getRandomHexdec(min, max, 2)}${getRandomHexdec(min, max, 2)}`;
