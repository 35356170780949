// Types
import { DOT } from '../../../classes/dot.class';
import { FormField } from '../../../types/common';
import { Data } from '../../../types/hit';
// Methods
import { getCSSPath, getDataDotFromEventTarget, getDataDotData, getDataFieldValue } from '../../utils/html/traversing';

/**
 * Function to be registered in mousedown mouse event listener (sends mousedown hit)
 * @param dot DOT instance
 * @param e Mouse event object specifying html element from which we start traversing and colecting properties
 * @param mousedownDataDotData Some services do not want to sent data-dot-data with mousedown, since it is already sent with impress
 */
export const mouseDown = (dot: DOT, e: MouseEvent, mousedownDataDotData?: boolean): void => {
  let target = (e.target || e.srcElement) as HTMLElement;

  const data: Data = {
    d: {
      pos: e.pageX + ',' + e.pageY,
      path: getDataDotFromEventTarget(e),
      // eslint-disable-next-line camelcase
      css_path: getCSSPath(target),
    },
  };

  if (mousedownDataDotData) {
    data.d = { ...data.d, ...getDataDotData(target) };
  }

  // In some cases target is type of `EvetTarget`. `EventTarget` doesn't have `nodeName`.
  // value of `nodeName` would be `undefined` and would raise an Error calling expression `target.nodeName.toLoweCase()`
  if (target.nodeName) {
    // Form fields extends data
    let name = target.nodeName.toLowerCase();
    if (name === 'input' || name === 'textarea' || name === 'select') {
      if ((target as FormField).name) {
        data.d.fieldname = (target as FormField).name;
      }
      if ((target as FormField).type !== 'password' && getDataFieldValue(target)) {
        data.d.fieldvalue = (target as FormField).value;
      }
    }

    // traverse dom until document root or link reached and extend data
    while (target && target !== document.documentElement) {
      name = target.nodeName.toLowerCase();
      if (name === 'a') {
        data.d.href = (target as HTMLLinkElement).href;
        if (!target.dataset.dotDisabled) {
          // Attribtu for mobile novinky.cz, where html structure causes sending of irrelevant hits
          const textFromAttr = target.dataset.dotText;
          data.d.text = (textFromAttr || target.textContent || target.innerText || '')
            .replace(/\s{2,}/g, ' ')
            .substring(0, 100);
        }
        break;
      }
      target = target.parentNode as HTMLElement;
    }
  }

  dot.hit('mousedown', data);
};

/**
 * Adds mousedown event listener to document object
 * @param dot DOT instance
 */
export const initMousedown = (dot: DOT): void => {
  document.addEventListener('mousedown', (e) => {
    mouseDown(dot, e, dot._cfg.mousedownDataDotData);
  });

  if (document && document.body) {
    document.body.setAttribute('cy-dot-mousedown-init', '');
  }
};

// API
export default { initMousedown, mouseDown };
