// Types
import { DOT } from '../../classes/dot.class';
import { NullableNumber } from '../../types/common';
import {
  PerformanceMetrics,
  PerformanceEntriesGetFns,
  PerformanceElementTiming,
  LayoutShift,
  GetEntriesFnSuffixes,
  GetMetricsByNames,
  GetEntriesByFns,
} from '../../types/performance';
// Methods
import { getGeometry } from '../geometry/geometry';

/**
 * Get timestamps diff (if possible, otherwise null)
 * @param startValue Timestamp to be subtracted
 * @param endValue Timestamp to be subtracted from
 */
export const _differenceValue = (startValue: NullableNumber, endValue: NullableNumber): NullableNumber => {
  const result = endValue - startValue;
  return !isNaN(result) && result >= 0 ? Math.round(result) : null;
};

/**
 * Get performance entries by specified method / param (if possible, othervise return submited default object)
 * @param fnSuffix Specifies fn to be chosen for metricts retrieval
 * @param metric Specifies param for fn to determine kind of metrics
 */
export const getPerformanceEntriesBy = (
  fnSuffix: GetEntriesFnSuffixes,
  metric: GetMetricsByNames,
  defaultValue?: PerformanceEntry
): PerformanceNavigationTiming | PerformanceEntry => {
  const fnSuffixSanitized = fnSuffix.charAt(0).toUpperCase() + fnSuffix.slice(1);
  const method = ('getEntriesBy' + fnSuffixSanitized) as GetEntriesByFns;

  const perf = window.performance as Performance;

  let methodValue = null;

  if (perf && perf[method]) {
    [methodValue] = (perf[method] as PerformanceEntriesGetFns)(metric);
  }

  return methodValue ? methodValue : defaultValue;
};

/**
 * Get metrics object (if api supported) to be sent inside load hit
 */
export const _getPerformanceMetrics = (): Promise<PerformanceMetrics | null> => {
  return new Promise<PerformanceMetrics | null>((resolve) => {
    const perfNavTiming = getPerformanceEntriesBy('type', 'navigation');
    const perfTiming = window.performance?.timing;

    const timing = (perfNavTiming || perfTiming) as
      | (PerformanceNavigationTiming & PerformanceTiming & PerformanceEntry)
      | null;

    if (!timing) {
      resolve(null);
    }

    const performanceMetrics: PerformanceMetrics = {
      redirect: _differenceValue(timing.redirectStart, timing.redirectEnd),
      appCache: _differenceValue(timing.fetchStart, timing.domainLookupStart),
      DNS: _differenceValue(timing.domainLookupStart, timing.domainLookupEnd),
      TCP: _differenceValue(timing.connectStart, timing.connectEnd),
      request: _differenceValue(timing.requestStart, timing.responseStart),
      response: _differenceValue(timing.responseStart, timing.responseEnd),
      processingToDI: _differenceValue(timing.responseEnd, timing.domInteractive),
      processingToDCL: _differenceValue(timing.domInteractive, timing.domContentLoadedEventStart),
      processingDCL: _differenceValue(timing.domContentLoadedEventStart, timing.domContentLoadedEventEnd),
      processingToDC: _differenceValue(timing.domContentLoadedEventEnd, timing.domComplete),
      processingL: _differenceValue(timing.loadEventStart, timing.loadEventEnd),
      processing: _differenceValue(timing.loadEventStart, timing.loadEventEnd),
      HTML: _differenceValue(timing.requestStart, timing.responseEnd),
      navigation: _differenceValue(timing.navigationStart || timing.startTime, timing.loadEventEnd),
      FCP: null,
      FID: null,
      fromRequestFCP: null,
      LCP: null,
      CLS: 0,
    };

    const fcp = getPerformanceEntriesBy('name', 'first-contentful-paint', {
      startTime: null,
    } as PerformanceEntry);
    performanceMetrics.FCP = fcp.startTime || null;

    const fid = getPerformanceEntriesBy('type', 'first-input', {
      startTime: null,
      duration: null,
      name: null,
    } as PerformanceEntry);
    performanceMetrics.FID = {
      startTime: fid.startTime ? Math.round(fid.startTime) : null,
      duration: fid.duration ? Math.round(fid.duration) : null,
      name: fid.name,
    };

    performanceMetrics.fromRequestFCP = _differenceValue(timing.requestStart, performanceMetrics.FCP);

    let largestContentFulPaintObserver = null;
    try {
      largestContentFulPaintObserver = new PerformanceObserver((entryList) => {
        const entries = (entryList.getEntries() as PerformanceElementTiming[]) || [];
        const lastEntry = entries[entries.length - 1];

        if ('renderTime' in lastEntry && 'loadTime' in lastEntry) {
          const LCP = lastEntry.renderTime + lastEntry.loadTime;
          performanceMetrics.LCP = LCP ? Math.round(LCP) : LCP;
        }
      });
      largestContentFulPaintObserver.observe({ type: 'largest-contentful-paint', buffered: true });
    } catch {
      // Some of performance utilities are not supported
    }

    let layoutShiftObserver = null;
    try {
      layoutShiftObserver = new PerformanceObserver((entryList) => {
        const entries = (entryList.getEntries() as LayoutShift[]) || [];
        let CLS = 0;

        entries.forEach((e) => {
          if (!e.hadRecentInput) {
            CLS += e.value;
          }
        });
        performanceMetrics.CLS = Math.round(CLS * 1000);
      });
      layoutShiftObserver.observe({ type: 'layout-shift', buffered: true });
    } catch {
      // Some of performance utilities are not supported
    }

    setTimeout(() => {
      if (largestContentFulPaintObserver && typeof largestContentFulPaintObserver.disconnect === 'function') {
        largestContentFulPaintObserver.disconnect();
      }
      if (layoutShiftObserver && typeof layoutShiftObserver.disconnect === 'function') {
        layoutShiftObserver.disconnect();
      }

      resolve(performanceMetrics);
    }, 100);
  });
};

/**
 * Send load hit with geometry (visible elements), time from init and performance metrics
 * @param dot DOT instance
 */
export const sendLoad = (dot: DOT): void => {
  // Collection (array) of html elements for visibility check
  dot.dataElms = Array.prototype.slice.call(document.querySelectorAll('[data-elm]'));
  const now = new Date().getTime();
  let timer = null;
  let boundLoadHit = null;

  const loadHit = async () => {
    clearTimeout(timer);
    timer = null;
    window.removeEventListener('click', boundLoadHit, true);
    window.removeEventListener('scroll', boundLoadHit, true);

    const data = {
      d: {
        time: now - dot.ts,
        performanceMetrics: await _getPerformanceMetrics(),
        ...getGeometry(dot.dataElms),
      },
    };
    dot.hit('load', data);
  };

  boundLoadHit = loadHit;

  window.addEventListener('click', boundLoadHit, true);
  window.addEventListener('scroll', boundLoadHit, true);

  timer = setTimeout(function () {
    boundLoadHit();
  }, 1000);
};

/**
 * If document loaded, sends load hit, otherwise adds load event to window object
 * @param dot DOT instance
 */
export const initLoad = (dot: DOT): void => {
  if (document.readyState === 'complete') {
    sendLoad(dot);
  } else {
    window.addEventListener('load', () => {
      sendLoad(dot);
    });
  }
};

/**
 * Adds load API to DOT instance
 * @param dot DOT instance
 */
export const addLoad = (dot: DOT): void => {
  dot.load = (): void => {
    return sendLoad(dot);
  };
};

// API
export default { initLoad, sendLoad, addLoad };
