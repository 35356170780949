// Types
import { DOT } from '../../classes/dot.class';
import { NullableString } from '../../types/common';
// Methods
import { getSid, runLinkdecoration } from '@iva/linkdecoration';

/**
 * Adds linkdecoration API to DOT instance
 * @param dot DOT instance
 */
export const addLinkdecoration = (dot: DOT): void => {
  dot.handleSid = runLinkdecoration; // backward compatibility
  dot.runLinkdecoration = runLinkdecoration;

  dot.getSid = (): NullableString => {
    return getSid();
  };
};

// API
export default { addLinkdecoration };
