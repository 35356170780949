// Types
import { DOT } from '../../../classes/dot.class';
import { Service } from '../../../types/config';
import { NullableString } from '../../../types/common';
// Constants
import { API_HOST, REGEX_DEV_OR_TEST_HOST, REGEX_IMEDIA_HOST, SERVICE_VARIANT, EVENTS } from '../../../constants/index';

/**
 * Replace / escape specific chars ([]) from name
 * @param name Name of url param to be sanitized
 */
export const _sanitizeParamName = (name: string): string => {
  return name.replace(/[\[\]]/g, '\\$&');
};

/**
 * Apply regex on string (url) -> find key/value pair -> return value
 * @param haystack String / part or url
 * @param needle Regular expression to be matched during search
 */
export const _getParamValueByRegExp = (haystack: string, needle: RegExp): NullableString => {
  const results = needle.exec(haystack);
  if (!results || !results[2]) {
    return null;
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Extract param value from url query (?)
 * @param name Name of url param (?) for which we want to retrieve value
 * @param url Uses current document URL if ommited
 */
export const getParameterFromURLQuery = (name: string, url?: string): NullableString => {
  const regExp = new RegExp('[?&]' + _sanitizeParamName(name) + '(=([^&#]*)|&|#|$)');
  return _getParamValueByRegExp(url || window.location.href, regExp);
};

/**
 * Extract param value from url hash (#)
 * @param name Name of url param (#) for which we want to retrieve value
 */
export const getParameterFromURLHash = (name: string): NullableString => {
  const regExp = new RegExp('[#&]' + _sanitizeParamName(name) + '(=([^&]*)|&)');
  return _getParamValueByRegExp(window.location.hash, regExp);
};

/**
 * Replace "imedia" with "seznam" in the URL string
 */
export const replaceImedia = (url: string): string => {
  return url.replace(REGEX_IMEDIA_HOST, 'h.seznam$1cz');
};

/**
 * Return filename of current variant of DOT script
 * @param variant DOT variant
 */
export const _getScriptFilename = (variant: Service): string | Error => {
  switch (variant) {
    case SERVICE_VARIANT.BASE:
      return 'dot-base.js';
    case SERVICE_VARIANT.TV:
      return 'dot-tv.js';
    case SERVICE_VARIANT.NANO:
      return 'dot-nano.js';
    case SERVICE_VARIANT.PARTNER:
      return 'dot-partner.js';
    case SERVICE_VARIANT.SMALL:
      return 'dot-small.js';
    case SERVICE_VARIANT.FULLTEXT:
      return 'dot-fulltext.js';
    default:
      return Error('Invalid DOT variant');
  }
};

/**
 * Obtain URL of the (first) DOT script loaded into the page
 * @param variant DOT variant
 */
export const getDotSource = (variant: Service): string => {
  const filename = _getScriptFilename(variant);
  const scripts = document.querySelectorAll(`script[src*="/${filename}"]`);
  const dotScript = scripts.item(0);

  return dotScript ? dotScript.getAttribute('src') : '';
};

/**
 * Get "server" param value from the DOT source URL query string
 * @param variant DOT variant
 */
export const _getAPIHostFromDotSourceParam = (variant: Service): NullableString => {
  const dotSource = getDotSource(variant);
  return getParameterFromURLQuery('server', dotSource);
};

/**
 * Get testing/development host from the DOT source URL
 * @param variant DOT variant
 */
export const _getTestAPIHostFromUrl = (variant: Service): NullableString => {
  const dotSource = getDotSource(variant);

  const match = replaceImedia(dotSource).match(REGEX_DEV_OR_TEST_HOST);
  if (match && match[0]) {
    return match[0];
  }

  return null;
};

/**
 * Decides where to hit API
 * @param variant DOT variant
 */
export const getAPIHost = (variant: Service): string => {
  return _getAPIHostFromDotSourceParam(variant) || _getTestAPIHostFromUrl(variant) || API_HOST;
};

/*
 * Gets document location href. When on seznamzpravy.cz, gets only part before ?
 */
export const getDocumentUrl = (): string => {
  const wlocation = window.location;

  // hack for seznamzpravy.cz
  const playerUrl = 'www.seznamzpravy.cz/iframe/player';
  if (wlocation.href.includes(playerUrl)) {
    return wlocation.href.substring(0, wlocation.href.indexOf('?'));
  } else {
    return wlocation.href;
  }
};

/**
 * Gets $zn param from location search and adds current url to history without it
 * If $zn parameter does not exist returns null
 */
export const getZnStringFromLocationSearch = (dot: DOT): NullableString => {
  const params = new URLSearchParams(window.location.search);
  let znstring: NullableString = null;

  params.forEach((value, key) => {
    try {
      const [decodedParamKey, decodedParamValue] = [decodeURIComponent(key), decodeURIComponent(value)];
      if (decodedParamKey === '_zn') {
        znstring = decodedParamValue;
        history.replaceState({}, '', window.location.href.replace(`${key}=${value}`, '').replace('&&', '&'));
        window.dispatchEvent(new CustomEvent(EVENTS.REDIRECT_SID));
        dot.log(`Got hashed sid from redirect: ${znstring}`);
        return;
      }
    } catch {
      //NO OP
    }
  });

  return znstring;
};

export const hasTCStringInLocationSearch = (): boolean => {
  return window.location.search.match(/[?&]_tc=/) !== null;
};

/**
 * Get domain part by desired level
 */
export const getDomainLevel = (hostname = window.location.hostname, level = 2, leadingDot = true): NullableString => {
  if (typeof hostname !== 'string') {
    return null;
  }
  hostname = hostname.replace(/https?:\/\//, '');
  let availableLevels = 0;
  const separators = hostname.match(/\./g);
  if (separators) {
    availableLevels = separators.length + 1;
  }

  if (!availableLevels || level > availableLevels) {
    return hostname;
  } else {
    return (leadingDot && level < availableLevels ? '.' : '') + hostname.split('.').slice(-level).join('.');
  }
};

/**
 * Removes "www." part from hostname
 * @param hostname page hostname
 */
export const omitWww = (hostname) => hostname.replace(/^www\./, '');

/**
 * Checks if page is loaded from local disk
 * @param protocol location protocol
 */
export const isPageOpenfromLocalFile = (): boolean => {
  try {
    return window.location.protocol === 'file:';
  } catch {
    return false;
  }
};

/**
 * Checks if script is on seznam.cz domain or its subdomain
 */
export const isSeznamDomain = (): boolean => {
  const hostname = omitWww(window.location?.hostname || '');
  return hostname === 'seznam.cz' || hostname.endsWith('.seznam.cz');
};

// API
export default {
  getDotSource,
  getParameterFromURLQuery,
  getParameterFromURLHash,
  replaceImedia,
  getAPIHost,
  getDocumentUrl,
  getZnStringFromLocationSearch,
  getDomainLevel,
};
