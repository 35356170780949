// Types
import { DOT } from '../classes/dot.class';
// Methods
import { isLocalStorageAvailable, setToLocalStorage, getFromLocalStorage } from './utils/storage/storage';
import { clearSpenttimeInterval, clearVisibilitychangeEvent } from './spenttime/spenttime';
import { addPropagatedHitsListener } from './hit/hit';
import { getCDLValue, getHighEntropyValues } from './utils/general/browser';
import { getCWReferrerCookie, removeCWReferrerCookie } from './utils/cookie/cookieWallCookie';
// Constants
import { LAST_SESSION_KEY, CDL_KEY, EVENTS } from '../constants/index';

/**
 * Setup new unique page ID and saves it into window.name
 * @param dot DOT instance
 */
export const setWindowNameWithId = (dot: DOT): void => {
  dot._cfg.id = '' + new Date().getTime() + Math.random();
  if (dot._cfg.spenttimeSpaReset) {
    clearSpenttimeInterval(dot);
    clearVisibilitychangeEvent(dot);
  }
  if (!window.name) {
    window.name = dot._cfg.id;
  }
};

/**
 * Sets model and platformVersion from Navigator.UserAgentData
 * @param dot DOT instance
 */
export const setUAData = async (dot: DOT) => {
  if (window.DOT?.UAData) {
    // top level DOT data can be used
    dot.UAData = window.DOT.UAData;
    return;
  }

  const UAData = await getHighEntropyValues();

  if (UAData?.model) {
    dot.UAData.model = UAData.model;
  }

  if (UAData?.platformVersion) {
    dot.UAData.platformVersion = UAData.platformVersion;
  }
};

/**
 * Sets cookieDeprecationLabel from Navigator.cookieDeprecationLabel
 * Saves to DOT instance and updates localStorage
 * @param dot DOT instance
 */
export const setCookieDeprecationLabel = async (dot: DOT) => {
  const localStorageValue = getFromLocalStorage(CDL_KEY) || '';
  dot.cookieDeprecationLabel = localStorageValue;

  const apiValue = await getCDLValue();
  if (apiValue !== localStorageValue) {
    setToLocalStorage(CDL_KEY, apiValue);
    dot.cookieDeprecationLabel = apiValue;
  }

  // let all DOT instances know that another check for CDL was done and value could be changed
  window.dispatchEvent(new CustomEvent(EVENTS.CDL_DONE));

  // listen to other DOT instances to sync value
  window.addEventListener(EVENTS.CDL_DONE, () => {
    dot.cookieDeprecationLabel = getFromLocalStorage(CDL_KEY) || '';
  });
};

/**
 * Initialize DOT instance
 * @param dot DOT instance
 */
export const init = (dot: DOT): void => {
  setUAData(dot);
  setCookieDeprecationLabel(dot);

  // Cookie Wall referrer
  const cwReferrer = getCWReferrerCookie();
  if (cwReferrer !== null) {
    const ref = decodeURIComponent(cwReferrer);
    Object.defineProperty(document, 'referrer', {
      get: () => ref,
      configurable: true,
    });
    removeCWReferrerCookie();
  }

  dot.ts = new Date().getTime();
  setWindowNameWithId(dot);
  dot.lastSession = dot.ts;

  if (isLocalStorageAvailable()) {
    /* Save current timestamp to localStorage. It enables to measure unique visits and time among individual visits. */
    dot.lastSession = parseInt(getFromLocalStorage(LAST_SESSION_KEY), 10) || 0;
    setToLocalStorage(LAST_SESSION_KEY, dot.ts.toString());
  }

  addPropagatedHitsListener(dot);
};

// API
export default { setWindowNameWithId, init };
