// Types
import { DOT } from 'classes/dot.class';
import { SavedHit } from 'types/hit';

/**
 * Clears hit queue
 *
 * @param dot DOT instance
 */
export const _clearPostponedHits = (dot: DOT): void => {
  dot.postponedQueue.length = 0;
};

/**
 * Check if hits should be postponed
 *
 * @param cookieRequestDone instance sid request status
 * @param udidRequestDone instance udid request status
 */
export const shouldPostponeHits = (dot: DOT): boolean => !dot.cookieRequestDone || !dot.udidRequestDone;

/**
 * Adds hit to postponed hits queue
 *
 * @param dot DOT instance
 * @param hit hit which will be postponed
 */
export const addToPostponedQueue = (dot: DOT, hit: SavedHit): void => {
  dot.postponedQueue.push(hit);
};

/**
 * Sends saved hits one by one with additional information
 *
 * @param dot DOT instance
 */
export const sendPostponedHits = (dot: DOT): void => {
  if (shouldPostponeHits(dot)) {
    dot.log('sendPostponedHits but sid or udid request not ready');
    return;
  }

  dot.postponedQueue.forEach((item) => {
    dot.hit(item.hit.action, item.hit, item.callback, item.useFetch);
  });
  _clearPostponedHits(dot);
  dot.log('postponed hits queue sent');
};

// API
export default {
  shouldPostponeHits,
  addToPostponedQueue,
  sendPostponedHits,
};
