// Types
import { DOT } from '../../classes/dot.class';
import { Data } from '../../types/hit';
// Methods
import { getGeometry } from '../geometry/geometry';

/**
 * Composes and sends delayed hit
 * @param dot DOT instance
 */
export const _sendDelayed = (dot: DOT): void => {
  const data: Data = {
    d: {
      count: dot.delayed.count,
      ...getGeometry(dot.dataElms),
    },
  };
  dot.hit(dot.delayed.action, data);
  dot.delayed.count = 0;
};

/**
 * Initialize sending delayed hit
 * @param dot DOT instance
 * @param action delayed hit action
 */
export const delay = (dot: DOT, action: string): void => {
  dot.delayed.action = action;

  if (dot.delayed.timeout) {
    clearTimeout(dot.delayed.timeout);
    dot.delayed.timeout = null;
  }

  dot.delayed.count++;

  dot.delayed.timeout = setTimeout((): void => {
    _sendDelayed(dot);
  }, dot._cfg.delay);
};

// API
export default { delay };
