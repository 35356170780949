import { NullableString } from '../../../types/common';

/**
 * Checks if localStorage functionality is available
 */
export const isLocalStorageAvailable = (): boolean => {
  const test = 'localStorageTest';
  try {
    // even access to `localStorage` property can raise SecurityError on some browsers
    if (!window.localStorage) {
      return false;
    }
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch {
    return false;
  }
};

/**
 * Sets the value of the pair identified by key to value into localStorage
 * If localStorage is not available, returns false, otherwise returns true
 * @param key item key
 * @param value item value
 */
export const setToLocalStorage = (key: string, value: string): boolean => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  // if run out of the allowed storage space limit per app/domain
  try {
    localStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
};

/**
 * Returns item from localStorage
 * @param key item key
 */
export const getFromLocalStorage = (key: string): NullableString => {
  if (!isLocalStorageAvailable()) {
    return null;
  }

  // if run out of the allowed storage space limit per app/domain
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

// API
export default { isLocalStorageAvailable, setToLocalStorage, getFromLocalStorage };
