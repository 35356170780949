// Types
import { DOT } from '../../classes/dot.class';
// Methods
import { sendPostponedHits } from '../postponedHitting/postponedHitting';
// Constants
import { UDID_REQUEST_TIMEOUT } from '../../constants/index';
// IVA Libs
import { marker } from '@iva/marker';

let markerCalled = false;

export const resetMarkerCalled = () => {
  markerCalled = false;
};

export const runMarker = (dot: DOT) => {
  if (markerCalled) {
    dot.log('marker already called');
    dot.udidRequestDone = true;
    return;
  }

  markerCalled = true;
  dot.log('calling marker');

  const done = () => {
    dot.udidRequestDone = true;
    sendPostponedHits(dot);
  };

  const timeout = setTimeout(() => {
    done();
    dot.log('marker done by timeout');
  }, UDID_REQUEST_TIMEOUT);

  marker().finally(() => {
    clearTimeout(timeout);
    done();
    dot.log('marker done');
  });
};
