import { EVENTS } from '../../../constants';
import { KeyValuePairs, NullableString } from '../../../types/common';
import { CookieData, CookieExpirationTuple } from '../../../types/cookie';
import { getDomainLevel } from '../general/url';

/**
 * Time multipliers for time from NOW calculation
 */
const multipliers: KeyValuePairs<number> = {
  seconds: 1000,
  minutes: 60,
  hours: 60,
  days: 24,
};

/**
 * Default / common cookie values
 */
const cookieDefaults: CookieData = {
  name: '',
  value: '',
  expires: [365, 'days'],
  path: '/',
  secure: null,
  sameSite: null,
  domain: null,
};

/**
 * Get expiration time as string suitable for cookie storing
 * @param expirationArray Tuple defining amount of units of expiration duration
 */
export const _getExpireTime = (expirationArray: CookieExpirationTuple): string => {
  const [value, units] = expirationArray;
  const multipliersKeys = Object.keys(multipliers);
  const unitIndex = multipliersKeys.findIndex((unit) => unit === units);
  const multiArray = !~unitIndex ? [] : multipliersKeys.map((k) => multipliers[k]).slice(0, unitIndex + 1);

  const offset = multiArray.reduce(
    (acc: number, current: number) => {
      return acc * current;
    },
    multiArray.length ? value : 0
  );

  const date = new Date();
  date.setTime(date.getTime() + offset);
  return date.toUTCString();
};

/**
 * Get composed cookie value string (value + metadata)
 * @param cookieData Object representing cookie value/metadata to be stored
 * @param remove Flag defining creation / removing of cookie
 */
export const _getCookieString = (cookieData: CookieData, remove = false): string => {
  if (!cookieData.name) {
    throw new Error('No cookie name provided');
  }

  const cookieValues = { ...cookieDefaults, ...cookieData };
  const { name, value, expires, path, secure, sameSite } = cookieValues;
  let { domain } = cookieValues;
  const expireTime = remove ? new Date(0).toUTCString() : _getExpireTime(expires as CookieExpirationTuple);

  if (!domain) {
    domain = getDomainLevel();
  }

  const cookieValue = [
    `${name}=${value}`,
    `Expires=${expireTime}`,
    `Path=${path}`,
    domain ? `Domain=${domain}` : '',
    sameSite ? `SameSite=${sameSite}` : '',
    secure ? 'Secure' : '',
  ].filter((el) => !!el);

  return cookieValue.join(';');
};

/**
 * Extract cookie Key / Value pair from single cookie string
 * @param cookieString single cookie key/value string
 */
export const _getCookieKeyValuePair = (cookieString: string): [string, string] => {
  const separatorIndex = cookieString.indexOf('=');
  const key = cookieString.substring(0, separatorIndex);
  const value = cookieString.substring(separatorIndex + 1);
  return [key.trim(), value.trim()];
};

/**
 * Get cookie value by name
 * @param requestedCookieName Name of cookie to retrieve
 */
export const getCookie = (requestedCookieName: string): NullableString => {
  const cookies = document.cookie.split(';');

  const foundCookie = cookies.reduce((acc, cookieString) => {
    const [key, value] = _getCookieKeyValuePair(cookieString);
    if (key === requestedCookieName) {
      acc = value;
    }
    return acc;
  }, null);

  return foundCookie;
};

/**
 * Gets sid cookie value from local domain
 */
export const getSidCookie = (): NullableString => getCookie('sid');

/**
 * Set cookie from data object
 * @param cookieData Data object for new cookie
 */
export const setCookie = (cookieData: CookieData): void => {
  document.cookie = _getCookieString(cookieData);
};

/**
 *  Set cookie from data object + report by CustomEvent
 */
export const setCookieAndReport = (cookieData: CookieData): void => {
  setCookie(cookieData);
  window.dispatchEvent(new CustomEvent(EVENTS.COOKIE_EVENT, { detail: cookieData }));
};

/**
 * Delete cookie (set expiration in past)
 * @param cookieData Data object for cookie to be removed
 */
export const removeCookie = (cookieData: CookieData): void => {
  document.cookie = _getCookieString(cookieData, true);
};

// API
export default { getCookie, setCookie, removeCookie, getSidCookie, setCookieAndReport };
