const rules = [
  {
    browser: 'firefox',
    test: /firefox\/(\d+)\./i,
  },
];

type UserAgent = {
  browser?: string;
  majorVersion?: number;
};

export const parseUserAgent = (rawUserAgent: string): UserAgent => {
  const userAgent: UserAgent = {};

  for (const { browser, test } of rules) {
    if (userAgent.browser) {
      break;
    }

    const matches = test.exec(rawUserAgent);

    if (matches) {
      userAgent.browser = browser;

      if (matches.length > 1) {
        userAgent.majorVersion = Number(matches[1]);
      }
    }
  }

  return userAgent;
};

/**
 * Detects Safari browser
 */
export const isSafari = () => {
  const { userAgent } = navigator;

  return userAgent.includes('Safari/') && !userAgent.includes('Chrome/') && !userAgent.includes('Chromium/');
};

/**
 * Detects Sbrowser
 */
export const isSbrowser = (): boolean => {
  return /SznProhlizec/.test(navigator.userAgent);
};

export const getHighEntropyValues = (): Promise<UADataValues | null> => {
  return new Promise((resolve) => {
    if (!navigator?.userAgentData?.getHighEntropyValues) {
      resolve(null);
      return;
    }
    navigator.userAgentData
      .getHighEntropyValues(['model', 'platformVersion'])
      .then((data) => {
        resolve(data);
      })
      .catch(() => {
        resolve(null);
      });
  });
};

export const getCDLValue = async (): Promise<string> => {
  if ('cookieDeprecationLabel' in navigator) {
    try {
      return await navigator.cookieDeprecationLabel.getValue();
    } catch {
      return '';
    }
  }

  return '';
};

// API
export default { isSafari, isSbrowser, getHighEntropyValues, getCDLValue };
