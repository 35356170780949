/* eslint-disable no-unused-vars */
// Types
import { DOT } from '../../classes/dot.class';
import { RequestInitWithPriority } from '../../types/common';
import { Hit, Data, PropagateHitsMessageEvent } from '../../types/hit';
// Methods
import { addUserAgreement, getCommon, serializeHit } from './composeData';
import { shouldLazyHit, addToHitQueue } from '../lazyHitting/lazyHitting';
import { addToPostponedQueue, shouldPostponeHits } from '../postponedHitting/postponedHitting';
import { getDataDot } from '../utils/html/traversing';
import { getTopWindow, isTopLevel } from '../utils/html/iframe';
import { isPageOpenfromLocalFile } from '../utils/general/url';
import { isSznAdmin } from '../identityObject/identityObject';
import { setImpressBeforeAdload } from '../impress/impress';

// Constants
import { EVENTS, HTTP_METHODS, PROPAGATE_HITS_BLACKLIST } from '../../constants';
import { extendHitWithSharedData } from './sharedData';

const _isItFinalSpenttimeHit = (hit: Hit) => hit.data?.action === 'spenttime' && hit.data?.type === 'final';

/**
 * Creates hit XHR request
 *
 * @param dot DOT instance
 * @param hit all serialized hit data
 * @param postData POST request body
 * @param callback called when XHR loads. In case of any error is called with Error param
 */
export const _xhr = (dot: DOT, hit: Hit, callback?: (arg?: Error) => void): void => {
  if (!window.XMLHttpRequest) {
    dot.log('XHR is not available. Cannot send hit.', 'error');
    return;
  }

  // config values
  const { url: configUrl, host, hitTimeout } = dot._cfg;

  // create request
  const xhr = new XMLHttpRequest();
  const sender = dot.variant ? `dot-${dot.variant.toLowerCase()}` : '';
  const senderVersion = process.env._DOT_VERSION || '';
  const url = _isItFinalSpenttimeHit(hit)
    ? `${configUrl.replace('__HOST__', host)}?client-id=${sender}&client-version=${senderVersion}`
    : configUrl.replace('__HOST__', host);

  // prepare JSON body
  const body = serializeHit(hit);
  if (!body) {
    dot.log('Invalid json data. Cannot prepare request body.');
    return;
  }

  // handle callback
  let callbackCalled = false;
  if (typeof callback === 'function') {
    const timeoutId = setTimeout(() => {
      const error = new Error('Hit timed out');
      error.name = 'TimeoutError';
      // eslint-disable-next-line no-use-before-define
      callCallback(error);
    }, hitTimeout);

    const callCallback = (argument?: Error): void => {
      clearTimeout(timeoutId);
      if (!callbackCalled) {
        callbackCalled = true;
        return callback(argument);
      }
    };

    xhr.onerror = (): void => {
      const error = new Error('Hit failed');
      error.name = 'NetworkError';
      callCallback(error);
    };

    xhr.onabort = (): void => {
      const error = new Error('Hit aborted');
      error.name = 'AbortError';
      callCallback(error);
    };

    xhr.onload = (): void => {
      callCallback();
    };
  }

  // Request preparation (method, url, asyncFlag).
  xhr.open(HTTP_METHODS.POST, url, true);

  // Send the proper header information along with the request
  xhr.setRequestHeader('Content-Type', 'application/json');

  // Sender (DOT) information
  xhr.setRequestHeader('X-Client-Id', sender);
  xhr.setRequestHeader('X-Client-Version', senderVersion);
  // Device information
  xhr.setRequestHeader('X-Sec-CH-UA-Model', dot.UAData.model);
  xhr.setRequestHeader('X-Sec-CH-UA-Platform-Version', dot.UAData.platformVersion);

  // Required to send with cookies.
  xhr.withCredentials = true;

  // Send request
  xhr.send(body);
};

/**
 * Creates hit Fetch promise
 *
 * @param dot DOT instance
 * @param hit all serialized hit data
 * @param postData POST request body
 * @param callback called when Fetch promise resolves. In case of any error is called with Error param
 */
export const _fetch = async (dot: DOT, hit: Hit, callback?: (arg?: Error) => void) => {
  // config values
  const { url: configUrl, host, hitTimeout } = dot._cfg;

  const sender = dot.variant ? `dot-${dot.variant.toLowerCase()}` : '';
  const senderVersion = process.env._DOT_VERSION || '';
  const url = _isItFinalSpenttimeHit(hit)
    ? `${configUrl.replace('__HOST__', host)}?client-id=${sender}&client-version=${senderVersion}`
    : configUrl.replace('__HOST__', host);

  // prepare JSON body
  const body = serializeHit(hit);
  if (!body) {
    dot.log('Invalid json data. Cannot prepare request body.');
    return;
  }

  // handle hit timeout
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    const error = new Error('Hit timed out');
    error.name = 'TimeoutError';
    // eslint-disable-next-line no-use-before-define
    callCallback(error);
  }, hitTimeout);

  // handle callback if exists
  let callbackCalled = false;
  const callCallback = (argument?: Error): void => {
    clearTimeout(timeoutId);
    if (typeof callback === 'function' && !callbackCalled) {
      callbackCalled = true;
      return callback(argument);
    }
  };

  try {
    const response = await fetch(url, {
      method: HTTP_METHODS.POST,
      keepalive: true,
      priority: 'high',
      credentials: 'include',
      signal: controller.signal,
      body,
      headers: {
        'Content-Type': 'application/json',
        'X-Client-Id': sender,
        'X-Client-Version': senderVersion,
        'X-Sec-CH-UA-Model': dot.UAData.model,
        'X-Sec-CH-UA-Platform-Version': dot.UAData.platformVersion,
      },
    } as RequestInitWithPriority);

    if (!response.ok) {
      const error = new Error('Hit failed');
      error.name = 'NetworkError';
      callCallback(error);
      throw error;
    }

    callCallback();
  } catch (e) {
    if (dot._cfg.error) {
      dot.error(e);
    }
    dot.log('Something went wrong with fetch request initialization, fallback to xhr');
    _xhr(dot, hit, callback);
  }
};

/**
 * Sends beacon API hit
 *
 * @param dot DOT instance
 * @param hit all serialized hit data
 */
export const _beaconApi = (dot: DOT, hit: Hit, callback?: (arg?: Error) => void): void => {
  const { url: configUrl, host } = dot._cfg;
  const sender = dot.variant ? `dot-${dot.variant.toLowerCase()}` : '';
  const senderVersion = process.env._DOT_VERSION || '';
  const url = `${configUrl.replace('__HOST__', host)}?client-id=${sender}&client-version=${senderVersion}`;

  // eslint-disable-next-line camelcase
  const device_info = {
    ...(dot.UAData.model && { model: dot.UAData.model }),
    // eslint-disable-next-line camelcase
    ...(dot.UAData.platformVersion && { platform_version: dot.UAData.platformVersion }),
  };

  const hitWithDeviceInfo = {
    ...hit,
    // eslint-disable-next-line camelcase
    ...(Object.keys(device_info).length && { device_info }),
  };

  const blob = new Blob([JSON.stringify(hitWithDeviceInfo)], { type: 'application/json' });

  try {
    navigator.sendBeacon?.(url, blob);

    if (typeof callback === 'function') {
      return callback();
    }
  } catch (e) {
    if (dot._cfg.error) {
      dot.error(e);
    }
    dot.log('Failed sendBeacon with a Blob whose type is not a CORS-safelisted value for the data argument', 'error');

    if (typeof callback === 'function') {
      return callback(e);
    }
  }
};

/**
 * Sends hit
 *
 * @param dot DOT instance
 * @param action hit action
 * @param data hit data object
 * @param callback called when hit is sent. In case of any error is called with Error param
 * @param useFetch should use Fetch API for sending hit
 */
export const sendHit = (
  dot: DOT,
  action: string,
  data: Data = {},
  callback?: (arg?: Error) => void,
  useFetch = false
): void => {
  if (isSznAdmin()) {
    dot.log('Current user is admin from helpdesk. Cannot send hit.');
    if (typeof callback === 'function') {
      return callback(new Error('admin_session'));
    }
    return;
  }

  const hit: Hit = {
    ...data,
    action,
    ...getCommon(dot),
  };

  if (hit?.action === 'adload') {
    // reset impressBeforeAdloadSent after sending adload hit
    setImpressBeforeAdload(dot, false);
  }

  // stopping hits from page loaded from local disk
  if (isPageOpenfromLocalFile()) {
    dot.log(`hit from file protocol ommitted: ${JSON.stringify(hit)}`, 'info');
    return;
  }

  // We have no idea why negative spenttime value happens, the code shouldn't generate them.
  // To not mess up with analytics, we do this sanity check here, see UZID-137 for more info.
  if (hit?.d?.action === 'spenttime' && (hit?.d?.time as number) < 0) {
    return;
  }

  // stopping hits with duplicate spenttime
  if (hit?.data?.time && dot?.spenttimeState?.data?.d?.time && dot?.spenttimeState?.data?.d?.time >= hit?.data?.time) {
    return;
  }

  if (dot.shouldLog()) {
    hit.instanceId = dot.instanceId;
  }

  // rename q -> query (Backward compatibility)
  if (hit.q !== undefined) {
    hit.query = hit.q;
    delete hit.q;
  }

  // rename d -> data (Backward compatibility)
  if (hit.d !== undefined) {
    hit.data = hit.d;
    delete hit.d;
  }

  // shared data for hits
  extendHitWithSharedData(hit, dot.sharedHitData);

  hit.lsid = data.lsid || hit.lsid;

  if (shouldLazyHit(dot.lazyHittingStatus)) {
    addToHitQueue(dot.hitQueue, { hit, callback, useFetch });
    return;
  }

  if (shouldPostponeHits(dot.cookieRequestDone)) {
    addToPostponedQueue(dot, { hit, callback, useFetch });
    return;
  }

  addUserAgreement(dot._cfg, hit);

  // propagate to _top
  const { propagateHits } = dot._cfg;
  if (!isTopLevel() && propagateHits && !PROPAGATE_HITS_BLACKLIST.includes(hit.action)) {
    getTopWindow().postMessage({ type: EVENTS.PROPAGATE_HIT_MESSAGE, hit }, '*');
  }

  const { forceBeacon } = dot._cfg;
  if (navigator.sendBeacon && (forceBeacon || _isItFinalSpenttimeHit(hit))) {
    _beaconApi(dot, hit, callback);
    return;
  }

  if (useFetch && window.fetch) {
    _fetch(dot, hit, callback);
    return;
  }

  _xhr(dot, hit, callback);
};

/**
 * Sends hit asynchronously
 *
 * @param dot DOT instance
 * @param action hit action
 * @param data hit data object
 * @param callback called when async hit is sent
 */
export const sendHitAsync = (dot: DOT, action: string, data: Data = {}, callback?: () => void): void => {
  callback = callback || function () {};
  setTimeout(() => {
    sendHit(dot, action, data);
    callback();
  }, 0);
};

/**
 * Listen and forward propagated hits from frames
 *
 * @param dot DOT instance
 */
export const addPropagatedHitsListener = (dot: DOT) => {
  window.addEventListener('message', (event: MessageEvent<PropagateHitsMessageEvent>) => {
    if (
      event.data.type === EVENTS.PROPAGATE_HIT_MESSAGE &&
      !PROPAGATE_HITS_BLACKLIST.includes(event.data.hit.action) &&
      dot === window.DOT
    ) {
      const propagatedHitData = { ...event.data.hit };

      // extend "path" for "mousedown" events
      const extendPath = event.data.hit.action === 'mousedown';
      let extendedPath = '';
      let sender = null;

      if (extendPath) {
        try {
          sender = (event.source as Window)?.frameElement;
        } catch {
          if (!sender) {
            const frames = document.querySelectorAll('iframe[src*="share.seznam.cz"]');
            for (let f = 0; f <= frames.length; f++) {
              if ((frames[f] as HTMLIFrameElement)?.contentWindow === event.source) {
                sender = frames[f];
                break;
              }
            }
          }
        }
      }

      // sender detected - extend "path"
      if (sender) {
        const dotData = getDataDot(sender as HTMLElement);
        extendedPath = dotData ? dotData + (propagatedHitData.data.path ? '/' : '') : '';
      }

      // prepend top level "path" to propagated "path"
      if (propagatedHitData.data?.path) {
        propagatedHitData.data.path = `${extendedPath}${propagatedHitData.data.path}`;
      }

      _xhr(dot, { ...propagatedHitData, service: dot._cfg.service });
    }
  });
};
