// SSP
export const SspAllowedSourcesObject = {
  'hp_feed': 'hp_feed', // deprecated, but we cannot afford to break websites using this
  'sekce-z-internetu': 'hp_feed',
  'denni-tisk': 'novinky',
  'novinky': 'novinky',
  'media': 'media',
  'z-boxiku': 'hp_box',
  'hp_box': 'hp_box',
  'search': 'search',
  'software': 'software',
  'sbrowser': 'sbrowser',
  'radio': 'radio',
  'tvseznam': 'tvseznam',
} as const;

export type SspAllowedSources = typeof SspAllowedSourcesObject;
