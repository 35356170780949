// Types
import { KeyValuePairs } from 'types/common';
import { IS_TABLE_KEYS_SPECIAL, IS_TABLE_LOGIN_STATE } from '../../constants/index';
import { IDENTITIES_SIMPLIFIED_SPECIAL } from '@iva/types/identity-storage';

/**
 * Converts login color values to "login" | "logout" | forget. When non-color value in param presented, returns undefined
 * @param loginValue green | orange | red
 */
export const _convertLoginValue = (loginValue: string): string | undefined => {
  return IS_TABLE_LOGIN_STATE[loginValue];
};

/**
 * Update special identities (rusId, said, premium) in sznIVA.IS from DOT config object
 * @param configObject dot config object
 */
export const updateIdentitiesFromConfig = (customConfig: KeyValuePairs<unknown>): void => {
  if (!window.sznIVA?.IS || !customConfig) {
    return;
  }

  const identitiesSpecial: IDENTITIES_SIMPLIFIED_SPECIAL = {};

  const specialKeys = Object.keys(IS_TABLE_KEYS_SPECIAL);

  specialKeys.forEach((key) => {
    const identityKey = IS_TABLE_KEYS_SPECIAL[key];

    if (customConfig[key] !== undefined) {
      identitiesSpecial[identityKey] = customConfig[key];

      if (identityKey === IS_TABLE_KEYS_SPECIAL.loginState) {
        identitiesSpecial[identityKey] = _convertLoginValue(customConfig[key] as string) || customConfig[key];
      }
    }
  });

  if (!!Object.keys(identitiesSpecial).length) {
    window.sznIVA.IS.updateIdentities(identitiesSpecial);
  }
};

/**
 * Updates sid in sznIVA.IS
 * @param sid string value
 */
export const updateSidIdentity = (sid: string): void => {
  if (window.sznIVA?.IS && sid) {
    window.sznIVA.IS.updateIdentities({ sid });
  }
};

/**
 * Checks if current user is admin from helpdesk
 */
export const isSznAdmin = (): boolean => {
  const login = window.sznIVA?.IS?.getIdentity('login');

  if (!login) {
    return false;
  }

  const flag = (login as KeyValuePairs<unknown>).adminName;

  return !!flag;
};

// API
export default { updateIdentitiesFromConfig, updateSidIdentity, isSznAdmin };
