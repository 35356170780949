// Types
import { DOT } from '../../classes/dot.class';
import { KeyValuePairs } from '../../types/common';
import { sendHit } from '../hit/hit';

/**
 * Array of error props to be filtered and sent
 */
const relevantErrorKeys = ['message', 'filename', 'lineno', 'colno'];

/**
 * Generate data from error object or string
 * @param e Error object
 * @return Object suitable for sending inside hit
 */
export const _getDataFromError = (e: ErrorEvent | string): KeyValuePairs<string | number> => {
  const d: KeyValuePairs<string | number> = { type: 'js' };

  // modern browsers
  if (e && typeof e === 'object') {
    relevantErrorKeys.forEach((k: keyof ErrorEvent) => {
      if (e[k]) {
        d[k] = e[k];
      }
    });

    if (e?.error?.stack) {
      d.stack = e.error.stack;
    }
    // older browser
  } else if (typeof e === 'string') {
    d.message = e;
  }

  return d;
};

/**
 * Send error hit with specified error data (optionaly trigger callback)
 * @param dot DOT instance
 * @param e Error object
 * @param callback Optional hit callback
 */
export const sendError = (dot: DOT, e: ErrorEvent, callback?: () => void): void => {
  if (!e) {
    return;
  }
  const d = _getDataFromError(e);
  sendHit(dot, 'error', { d }, callback);
};

/**
 * Adds error event to window object
 * @param dot DOT instance
 */
export const initError = (dot: DOT): void => {
  window.addEventListener('error', (e) => {
    sendError(dot, e);
  });
};

/**
 * Adds error API to DOT instance
 * @param dot DOT instance
 */
export const addError = (dot: DOT): void => {
  dot.error = (e?: ErrorEvent, callback?: () => void): void => {
    sendError(dot, e, callback);
  };
};

// API
export default { sendError, initError, addError };
