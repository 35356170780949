import { NullableBoolean } from './common';

export const ServiceValues = {
  BASE: 'BASE',
  TV: 'TV',
  FULLTEXT: 'FULLTEXT',
  NANO: 'NANO',
  PARTNER: 'PARTNER',
  SMALL: 'SMALL',
} as const;

export const LoginStateValues = {
  GREEN: 'GREEN',
  ORANGE: 'ORANGE',
  RED: 'RED',
} as const;

export type Service = (typeof ServiceValues)[keyof typeof ServiceValues];

export type Premium = NullableBoolean;

export type LoginState = (typeof LoginStateValues)[keyof typeof LoginStateValues] | null;

export type UserAgreement = { dopo: boolean } | null;

export type Version = `${bigint}.${bigint}`;

export type ConfigValue = string | number | boolean;

export interface Config {
  /* enable to index Data using string type */
  [index: string]: any;

  /* host of the URL we hit */
  host: string;

  /* we are hitting this URL, '__HOST__' will be replaced by Config.host */
  url: string;

  /* URL of iframe used for setting third-party cookies, '__HOST__' will be replaced by Config.host */
  thirdPartyCookieUrl: string;

  /* debouncing time of hitting scroll/resize [seconds] */
  delay: number;

  /* search query */
  query: string;

  /* service ID */
  service: string;

  /* local session ID */
  lsid: string;

  /* page ID */
  id: string;

  /* user ID for GDPR (sent as ri) */
  rusId: number | '';

  /* user ID (rusId) chifre */
  said: string;

  /* login state - logged in, orange, not logged in (sent as los) */
  loginState: LoginState;

  /* verze api */
  version: Version;

  /* true značí použití DOTu v SPA aplikaci */
  spa: boolean;

  /* ID for pairing backend data with collected hits (sent as pvid) */
  pageViewId: string;

  /* whether to send impress request automatically after configuration */
  impress: boolean;

  /* whether to start mousedown measuring automatically after configuration */
  mousedown?: boolean;

  /* whether to measure onLoad event */
  load?: boolean;

  /* whether to measure onError event and uncought exceptions */
  error?: boolean;

  /* AB testing version ID */
  abtest: string;

  /* service variant ID */
  serviceVariant: string;

  /* time limit of hit */
  hitTimeout: number;

  /* whether to save hits and hit them on beforeunload or next call of configure with lazyLoadingEnabled: false */
  lazyLoadingEnabled?: boolean;

  /* whether to send data-dot-data on mousedown event */
  mousedownDataDotData?: boolean;

  /* user agreement object for recass, sent along the spenttime hit */
  u_agrm: UserAgreement;

  /* whether the logged in user is a premium user */
  premium: Premium;

  /* whether to reset spenttime on configure call in SPA */
  spenttimeSpaReset: boolean;

  /* send all hits via beaconApi */
  forceBeacon: boolean;

  /* whether to use server-side refresher */
  serverSideRefresher?: boolean;

  /**
   * enable dop_ param cleanup after spenttime initialized
   */
  dopParamCleanup?: boolean;

  /**
   * enable hit duplicating/propagating to the top window if this instance runs in a frame
   */
  propagateHits?: boolean;

  /* login badge, if true medailonek is on a webpage */
  badge?: boolean;

  // properties only for certain variants have to be marked as optional
}
