import { SPENTTIME_MIN_DURATION } from '../../constants';
import { KeyValuePairs } from '../../types/common';

export const _validateRusId = (rusId: any): number | '' => {
  const NumberRusId = parseInt(rusId, 10);
  return Number.isNaN(NumberRusId) ? '' : NumberRusId;
};

export const _validateSpenttimeDuration = (spenttimeDuration: unknown): number => {
  const duration = Number(spenttimeDuration);

  if (Number.isNaN(duration)) {
    return SPENTTIME_MIN_DURATION;
  }

  return duration < SPENTTIME_MIN_DURATION ? SPENTTIME_MIN_DURATION : duration;
};

/**
 * Validates dot config object
 * @param customConfig DOT config object
 */
export const validateConfig = (customConfig: KeyValuePairs<unknown>): void => {
  if (!customConfig) {
    return;
  }

  if (customConfig.rusId !== undefined) {
    customConfig.rusId = _validateRusId(customConfig.rusId);
  }

  if (customConfig.spenttimeDuration !== undefined) {
    customConfig.spenttimeDuration = _validateSpenttimeDuration(customConfig.spenttimeDuration);
  }
};

// API
export default { validateConfig };
