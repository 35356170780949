// Types
import { DOT } from '../../classes/dot.class';
import { NullableString, NullableNumber, KeyValuePairs } from '../../types/common';
import { CookieData, ParsedSid, SidCookieRequestResponse } from '../../types/cookie';
// Methods
import { getSidCookie, setCookieAndReport } from '../utils/cookie/cookie';
import { isSeznamDomain } from '../utils/general/url';
import { sendPostponedHits } from '../postponedHitting/postponedHitting';
import { updateSidIdentity } from '../identityObject/identityObject';
import { setToLocalStorage } from '../utils/storage/storage';
// Constants
import { EVENTS, HTTP_METHODS, MAX_ITERATIONS, INTERVAL_TIMEOUT } from '../../constants/index';
// IVA Libs
import { CONSENT, SID_STORE_CHECK } from '@sklik/cmp2-common';
import { SID_UTILS } from '@iva/utils';

/**
 * Get body data for XHR request (based on SID cookie)
 * Temporarly adds consent string to body
 */
export const _createRequestBody = (): NullableString => {
  const sid = getSidCookie();
  const consent = CONSENT.getConsentFromCookieList()?.consentString;

  const params = [SID_UTILS.isValidSid(sid) ? `dsid=${sid}` : null, consent ? `euconsent-v2=${consent}` : null].filter(
    Boolean
  );

  return params.length ? params.join('&') : null;
};

/**
 * Sets sid on foreign domain (all domains except seznam.cz)
 * @param sid cookie string value
 */
export const setSidIntoForeignDomain = (sid: string): void => {
  updateSidIdentity(sid);
  setToLocalStorage('sid', sid);
  setCookieAndReport({ value: sid, name: 'sid', expires: [3, 'days'] });
};

/**
 * Sets sid on seznam domain
 * @param sid cookie string value
 */
export const setSidIntoSeznamDomain = (dot: DOT, sid: string): void => {
  const sidCookie = getSidCookie();
  if (sidCookie) {
    dot.log('Sid cookie already exists.');
    window.dispatchEvent(
      new CustomEvent(EVENTS.COOKIE_EVENT, { detail: <CookieData>{ name: 'sid', value: sidCookie } })
    );
    return;
  }
  // no sid currently in domain
  updateSidIdentity(sid);
  setToLocalStorage('sid', sid);
  setCookieAndReport({ value: sid, name: 'sid', expires: [3, 'days'] });
};

/**
 * Saves sid into browser
 * @param sid cookie string value
 */
export const saveSidIntoBrowser = (dot: DOT, sid: string): void => {
  if (isSeznamDomain()) {
    dot.log('Save sid on seznam domain.');
    setSidIntoSeznamDomain(dot, sid);
    return;
  }
  dot.log('Save sid on foreign domain.');
  setSidIntoForeignDomain(sid);
};

/**
 * XHR success callback (sets cookie and logs info data)
 * @param service String identificator of service
 * @param response SXHR response string
 */
export const _handleCookieRequestResponse = (dot: DOT, service: string, response: string): void => {
  dot.log('Cookie request successfully received response.');

  let data: SidCookieRequestResponse | null = null;

  // parse received data
  try {
    data = JSON.parse(response);
  } catch {
    // no-op
    return;
  }

  // check response structure
  if (!(data.status === 'ok' && data.sid)) {
    dot.log('Something bad in cookie response: ' + response);
    return;
  }

  if (!SID_UTILS.isValidSid(data.sid)) {
    dot.log(`Got invalid sid ${data.sid}`);
    return;
  }

  try {
    // because of AMP
    saveSidIntoBrowser(dot, data.sid);
  } catch {
    // no-op
    return;
  }

  dot.log(`Sid cookie synchronised to service ${service}`);
  dot.log(`show sid ${data.sid}`);
};

/**
 * Additionally waits for consent with purpose one and sends sid request
 * @param sidXhr
 * @param requestBody
 */
export const sendRequestAfterAdditionalConsent = (
  dot: DOT,
  sidXhr: XMLHttpRequest,
  requestBody: NullableString
): void => {
  dot.log('Still waiting for potentional consent cookie with purposeOne...');

  let interval = null;
  let iterationCount = 0;

  const finishInterval = () => {
    clearInterval(interval);
    interval = null;
  };

  const trySendRequest = () => {
    const consentCookie = CONSENT.getConsentFromCookieList();
    if (!consentCookie) {
      // no consent cookie
      return;
    }
    finishInterval();
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener(EVENTS.SCMP_CONSENT_SET, scmpHandler);

    // consent cookie exists
    if (CONSENT.hasPurposeOne(consentCookie.consentString)) {
      dot.log('Got consent with purposeOne. Sending sid request.');
      sidXhr.send(requestBody);
    } else {
      dot.log('Got consent without purposeOne. Cannot send sid request.');
    }
  };

  const scmpHandler = () => {
    // try to send sid request legally
    trySendRequest();
  };

  const periodicalCheck = () => {
    if (iterationCount >= MAX_ITERATIONS) {
      // stop interval and listen for CMP event at least
      finishInterval();
      window.addEventListener(EVENTS.SCMP_CONSENT_SET, scmpHandler);
    }

    // try to send sid request legally
    trySendRequest();

    // keep checking
    iterationCount++;
  };

  // set up periodical check
  interval = setInterval(periodicalCheck, INTERVAL_TIMEOUT);
};

/**
 * Prepare and send cookie sync XHR request
 * @param dot DOT instance
 * @param config Variant specific config
 */
export const createCookieRequest = (dot: DOT): void => {
  if (!window.XMLHttpRequest) {
    dot.log('XHR is not available.', 'error');
    return;
  }

  const { thirdPartyCookieUrl, host, service } = dot._cfg;

  const xhr = new XMLHttpRequest();
  dot.cookieRequest = xhr;

  const url = thirdPartyCookieUrl.replace('__HOST__', host);
  const body = _createRequestBody();
  const sender = dot.variant ? `dot-${dot.variant.toLowerCase()}` : '';
  const senderVersion = process.env._DOT_VERSION || '';

  xhr.onreadystatechange = () => {
    if (xhr.readyState !== 4) {
      // no-op
      return;
    }

    dot.cookieRequestDone = true;

    if (xhr.status === 200 && xhr.responseText) {
      _handleCookieRequestResponse(dot, service, xhr.responseText);
    } else {
      dot.log('Something went wrong with cookie request.', 'error');
    }

    sendPostponedHits(dot);
  };

  // Request preparation (method, url, asyncFlag).
  xhr.open(HTTP_METHODS.POST, url, true);

  // Send the proper header information along with the request
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  // Sender (DOT) information
  xhr.setRequestHeader('X-Client-Id', sender);
  xhr.setRequestHeader('X-Client-Version', senderVersion);

  // Required to send with cookies.
  xhr.withCredentials = true;

  // Send request
  SID_STORE_CHECK.sidLegalStoreCheckPromise().then((allowed) => {
    if (allowed) {
      xhr.send(body);
      dot.log('Can now legally store SID (partner with purposeOne consent)');
    } else {
      dot.log('Can not legally store SID (partner without purposeOne consent)');
      xhr.dispatchEvent(new CustomEvent('load'));
      window.dispatchEvent(new CustomEvent(EVENTS.COOKIE_EVENT, { detail: <CookieData>{ name: 'sid' } }));
      dot.cookieRequestDone = true;

      dot.log('Processing postponed hits');
      sendPostponedHits(dot);
      dot.log('Hits gonna be sent even without sid');

      // wait for potentional consent and send request later
      sendRequestAfterAdditionalConsent(dot, xhr, body);
    }
  });
};

/**
 * Parse sid cookie string and return object
 */
export const _parseCookieValue = (value?: string): ParsedSid | null => {
  if (!value) {
    return null;
  }
  const params = value.trim().split('|');
  const splitParams: KeyValuePairs<string | number> = {};
  params.forEach((str) => {
    const substr: RegExpExecArray | null[] = /(.+)=(.+)/g.exec(str) || [null];
    if (substr[1]) {
      const [, paramKey, paramValue] = substr;
      splitParams[paramKey] = paramValue;
    }
  });
  return splitParams as ParsedSid;
};

/**
 * Get time (in ms) representing last sid update (or null if no sid)
 */
export const _getTimeSinceCookieUpdate = (value?: string): NullableNumber => {
  // te=<timestamp> - UNIX timestamp (with millisecond precision) of last contact with this SID.
  // It is set to current UNIX time each time a request with this SID is processed.
  const sidCookieData = _parseCookieValue(value);
  if (!sidCookieData?.te) {
    return null;
  }
  return new Date().getTime() - (sidCookieData.te as number) * 1000;
};

/**
 * Should cookie refresh be disabled (validate serverSideRefresher config and sid max age)?
 */
export const shouldDisableCookieRefresh = (serverSideRefresher?: boolean): boolean => {
  const sid = getSidCookie();
  const maxAgeSidCookie = 24 * 60 * 60 * 1000; // 24 hours
  return !!(serverSideRefresher && sid && _getTimeSinceCookieUpdate(sid) < maxAgeSidCookie);
};

/**
 * Checking that sid cookie exists
 * @returns true - sid cookie exists, false - no
 */
export const hasAlreadySetSid = (): boolean => {
  return window.sznIVA?.linkdec?.sid?.state === 'done' && window.sznIVA.linkdec.sid.sid === getSidCookie();
};

// API
export default {
  createCookieRequest,
  sendRequestAfterAdditionalConsent,
  shouldDisableCookieRefresh,
  hasAlreadySetSid,
};
